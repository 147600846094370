<template>
  <div class="h-full mt-2" style="overflow: visible !important;">
    <div class="bottom_space rounded h-full bg-white">
      <div v-if="organizationList !== null">
        <div class="grid grid-cols-1 md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2">
          <div class="card rounded-lg bg-white p-4 relative hover:bg-gray-50 transition group">
            <div class="flex items-center justify-between">
              <div class="heading-3 text-text1">● User Detail:</div>
              <div class=" text-primary heading-4 font-semibold cursor-pointer " @click="updateRedirect(1)">
                <i class="fas fa-pen-to-square"></i>
              </div>
            </div>
            <div class="p-1 pt-2">
              <p class="mb-0 items-center flex">
                <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:170px">First Name</p></span>
                <span><p class="mb-0 dotwidth">:</p></span>
                <span class="text-text1 font-semibold heading-5 overFlowParaA">{{organizationList.firstName}}</span>
              </p>
            </div>
            <div class="p-1">
              <p class="mb-0 items-center flex">
                <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:170px">Last Name</p></span>
                <span><p class="mb-0 dotwidth">:</p></span>
                <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="organizationList.lastName !== ''">{{organizationList.lastName}}</span>
                <span class="text-text1 heading-5" v-else>---</span>
              </p>
            </div>
            <div class="p-1">
              <p class="mb-0 items-center flex">
                <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:170px">Contact Number</p></span>
                <span><p class="mb-0 dotwidth">:</p></span>
                <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="organizationList.ContactNo !== ''">{{organizationList.ContactNo | phone}}</span>
                <span class="text-text1 heading-5" v-else>---</span>
              </p>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 my-4">
          <div class="card rounded-lg bg-white p-4 relative hover:bg-gray-50 transition group">
            <div class="flex items-center justify-between">
              <div class="heading-3 text-text1">● Login Configuration:</div>
              <div class=" text-primary heading-4 font-semibold cursor-pointer " @click="updateRedirect(2)">
                <i class="fas fa-pen-to-square"></i>
              </div>
            </div>
            <div class="p-1 pt-2">
              <p class="mb-0 items-center flex">
                <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:170px">Email</p></span>
                <span><p class="mb-0 dotwidth">:</p></span>
                <span class="text-text1 font-semibold heading-5 overFlowParaA">{{organizationList.email}}</span>
              </p>
            </div>
            <div class="p-1">
              <p class="mb-0 items-center flex">
                <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:170px">Password</p></span>
                <span><p class="mb-0 dotwidth">:</p></span>
                <span class="text-text1 font-semibold heading-5">******</span>
              </p>
            </div>
          </div>
        </div>
    </div>
  </div>
  <div v-if="userUpdate" class="popup_overlay px-4">
      <div class="custom_dialog rounded-lg pb-4" style="width: 600px;max-height: 95%;">
        <div class="relative bg-white">
          <div>
            <div class="bg-primary flex justify-between items-center p-3">
              <div class=" font-medium text-white heading-3">
                Update User Detail
              </div>
              <div>
                <span class=" text-text1 heading-6 font-semibold cursor-pointer bg-gray1 px-3 py-2 rounded-lg uppercase" @click="userUpdate = false;resetError()">
                  Cancel
                </span>
                <span class=" text-white heading-6 ml-2 font-semibold cursor-pointer bg-success px-3 py-2 rounded-lg uppercase" @click="saveUpdate(1)">
                  Update
                </span>
              </div>
            </div>
            <div class="p-2">
              <div class="">
                <div class="box col-span-6 p-2">
                  <p class="label_css">First Name</p>
                  <input
                  :class="reqObj.fnameErr ? 'border border-error' :'border border-gray-500 focus:border-primary'"
                  class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4" 
                  placeholder="First Name"
                  v-model="reqObj.firstName" @keypress="reqObj.fnameErr=false"
                  >
                  <p class="text-error" v-if="reqObj.fnameErr">First Name is Required</p>
                </div>
              </div>
              <div class="">
                <div class="box col-span-6  p-2">
                  <p class="label_css">Last Name</p>
                  <input
                  class="outline-none border border-gray-500 focus:border-primary  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4" 
                  placeholder="Last Name"
                  v-model="reqObj.lastName"
                  >
                </div>
              </div>
              <div class="">
                <div class="box col-span-6  p-2">
                  <p class="label_css">Phone Number</p>
                  <input type="text"
                  @blur="checkContactNumber()"
                  maxlength="12"
                  :class="reqObj.contactErr ? 'border border-error' :'border border-gray-500 focus:border-primary'"
                  class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4" 
                  placeholder="Phone Number"
                  v-model="reqObj.ContactNo" @keypress="reqObj.contactErr=false"
                  >
                  <p class="text-error" v-if="reqObj.contactErr">Please enter valid contact number</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  <div v-if="openEditPopup" class="popup_overlay px-4">
      <div class="custom_dialog rounded-lg pb-4" style="width: 600px;max-height: 95%;">
        <div class="relative bg-white">
          <div>
            <div class="bg-primary flex justify-between items-center p-3">
              <div class=" font-medium text-white heading-3">
                Update 
              </div>
              <div>
                <span class=" text-text1 heading-6 font-semibold cursor-pointer bg-gray1 px-3 py-2 rounded-lg uppercase" @click="openEditPopup = false;resetError()">
                  Cancel
                </span>
                <span class=" text-white heading-6 ml-2 font-semibold cursor-pointer bg-success px-3 py-2 rounded-lg uppercase" @click="saveUpdate(2)">
                  Update
                </span>
              </div>
            </div>
            <div class="p-2">
              <div class="">
                <div class="box col-span-6  p-2">
                  <p class="label_css">Email</p>
                  <input
                  @blur="checkEmail()"
                  :class="reqObj.emailErr !== '' ? 'border border-error' :'border border-gray-500 focus:border-primary'"
                  class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4" 
                  placeholder="Email"
                  v-model="reqObj.email" @keypress="reqObj.emailErr=''"
                  >
                  <p class="text-error" v-if="reqObj.emailErr !== ''">{{reqObj.emailErr}}</p>
                </div>
                <div class="box col-span-6 p-2 relative">
                  <p class="label_css">Password</p>
                  <input class="outline-none border border-gray-500 focus:border-primary text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4"
                    v-model="reqObj.password"
                    placeholder="Password"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                  >
                  <span class="absolute inset-y-0 top-4 right-2 flex items-center pl-3">
                    <span class="p-1 pr-2 focus:outline-none focus:shadow-outline">
                      <span v-if="!showPassword" @click="showPassword = !showPassword" class="cursor-pointer"><img height="26px" width="26px" src="@/assets/images/svg/hide-pass.svg" alt="clearInput"></span>
                     <span v-else @click="showPassword = !showPassword" class="cursor-pointer"><img height="16px" width="24px" src="@/assets/images/svg/show-pass.svg" alt="clearInput"></span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
</template>
<script>
import { validateEmail} from "@/utils/validation.js";
import {getEncryptedPassword} from '@/utils/encrytPassword.js'
import ADMINAPI from '@/View/Admin/api/Admin.js'
import deboucneMixin from '@/mixins/debounce.js'
import {removeCookies} from '@/utils/cookies.js'
export default {
  components: {
  },
  mixins: [deboucneMixin],
  props: [],
  data () {
    return {
      userUpdate: false,
      showPassword: false,
      reqObj:{
        firstName: "",
        fnameErr: false,
        lastName: "",
        email: "",
        password: "",
        emailErr: "",
        passwordErr: false,
        contactErr: false,
        ContactNo: ""
      },
      organizationList: null,
      openEditPopup: false,
    }
  },
  created() {
  },
  mounted () {
    document.title = 'Login User'
    this.getDetail()
  },
  watch: {
  },
  methods: {
    checkContactNumber() {
      const phonePattern = /^[0-9]{10}$/;
      if (!phonePattern.test(this.reqObj.ContactNo)) {
        this.reqObj.contactErr = true;
      } else {
        this.reqObj.contactErr = false;
      }
    },
    checkEmail() {
      if (validateEmail(this.reqObj.email) === false) {
        this.reqObj.emailErr = "Please enter valid email";
      } else {
        this.reqObj.emailErr = ''
      }
    },
    updateRedirect (from) {
      this.reqObj.firstName = this.organizationList.firstName
      this.reqObj.lastName = this.organizationList.lastName
      this.reqObj.email = this.organizationList.email
      this.reqObj.password = this.organizationList.password
      this.reqObj.ContactNo = this.organizationList.ContactNo
      this.reqObj.fnameErr = false,
      this.reqObj.emailErr = "",
      this.reqObj.passwordErr = false
      this.reqObj.contactErr = false
      if (from === 1) {
        this.userUpdate = true
      } else {
        this.openEditPopup = true
      }
    },
    saveUpdate (from) {
      if (from === 1) {
        let isError = false
        if (this.reqObj.firstName === '') {
          isError = true
          this.reqObj.fnameErr = true
        }
        if (this.reqObj.contactErr) {
          isError = true;
          this.reqObj.contactErr = true;
        }
        if (!isError && !this.reqObj.fnameErr && !this.reqObj.contactErr) {
          this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
          ADMINAPI.UpdateLoginUserDetail(
            this.reqObj,
            response => {
              this.resetError()
              this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
              ADMINAPI.GetUserDetailLogin(
                response2 => {
                  console.log('res')
                  localStorage.removeItem('employeeData1')
                  this.organizationList = response2.Data
                  location.reload(true)
                  this.$store.dispatch('SetLoader', {status: false, message: ''})
                  localStorage.setItem('employeeData1', JSON.stringify(response2.Data))
                },
                error => {
                  this.$store.dispatch('SetLoader', {status: false, message: ''})
                  this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
                }
              )
              this.userUpdate = false
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            },
            error => {
              this.$store.dispatch('SetLoader', {status: false, message: ''})
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            }
          )
        }
      } else {
        let isError = false
        if (this.reqObj.email === '') {
          isError = true
          this.reqObj.emailErr = 'Email Label is required'
        }
        if (this.reqObj.emailErr === '') {
          if (validateEmail(this.reqObj.email) === false) {
            this.reqObj.emailErr = "Please enter valid email";
            isError = true
          }
        }
        console.log('isError', isError)
        if (!isError && this.reqObj.emailErr === '' ) {
          if (this.reqObj.password !== '') {
            this.reqObj.password = getEncryptedPassword(this.reqObj.password)
          }
          this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
          ADMINAPI.UpdateLoginUserDetail(
            this.reqObj,
            response => {
              this.resetError()
              this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
              removeCookies('saToken')
              localStorage.clear()
              this.$store.dispatch('GetEmployeePermission', true)
              this.$router.push({name: 'login'})
              this.userUpdate = false
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            },
            error => {
              this.$store.dispatch('SetLoader', {status: false, message: ''})
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            }
          )
        }
      }
      
    },
    getDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.GetUserDetailLogin(
        response => {
          console.log('res')
          this.organizationList = response.Data
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    resetError () {
      this.reqObj.fnameErr = false
      this.reqObj.emailErr = ""
      this.reqObj.contactErr = false
      this.reqObj.passwordErr = false
    },
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
.table_containder {
  padding: 1px;
}

.dotwidth {
  width: 15px;
}
</style>
